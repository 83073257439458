@use "sass:meta" as ---5bjxerk8lwt;//login-new-css
.welcomeBase {
    * {
        box-sizing: border-box;
    }
    html:not([dir="rtl"]) .ps-4 {
        padding-left: 2.2rem !important;
    }
    body {
        background: #f6f5f7 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-family: "Montserrat", sans-serif;
        height: 100vh;
        margin: -20px 0 50px;
    }
    h1 {
        font-weight: bold;
        margin: 0;
    }
    h2 {
        text-align: center;
    }
    p {
        font-size: 14px;
        font-weight: 100;
        line-height: 20px;
        letter-spacing: 0.5px;
        margin: 20px 0 30px;
    }
    span {
        font-size: 12px;
    }
    a {
        color: #333;
        font-size: 14px;
        text-decoration: none;
        margin: 15px 0;
    }
    .loginBtn {
        //background: $adminprimaryColor;
        border-radius: 20px;
        color: #ffffff;
        font-size: 13px;
        font-weight: bold;
        padding: 12px 45px;
        letter-spacing: 1px;
        text-transform: uppercase;
        transition: transform 80ms ease-in;
        /*  &:hover {
            background-color: transparent !important;
        } */
        background: linear-gradient(to left, $secondaryColor 50%, $primaryColor 50%) right;
        background-size: 200%;
        transition: 0.5s ease-out !important;
        &:hover {
            background-position: left;
            border: 0 !important;
            color: #fff !important;
        }
    }

    .changeLoginBtn {
        //background: transparent;
        border-radius: 20px;
        border: 1px solid $primaryColor;
        color: $primaryColor;
        font-size: 13px;
        font-weight: bold;
        padding: 11px 24px;
        letter-spacing: 1px;
        text-transform: uppercase;
        transition: transform 80ms ease-in;
        /* &:hover {
            background-color: transparent !important; 
            border-color: #000 !important;
        } */
        background: linear-gradient(to left, #fff 50%, $primaryColor 50%) right;
        background-size: 200%;
        transition: 0.5s ease-out !important;
        &:hover {
            background-position: left;
            border: 0 !important;
            color: #fff !important;
        }
    }
    button:active {
        transform: scale(0.95);
    }
    button:focus {
        outline: none !important;
        box-shadow: none !important;
    }

    form {
        background-color: #ffffff;
        //display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 50px;
        height: 100%;
        width: 100%;
        //text-align: center;
    }
    input {
        border: none;
        padding: 12px 15px;
        margin: 8px 0;
        width: 100%;
        border-bottom: 1px solid #ccc;
        border-radius: 0;
    }
    .container {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
        position: relative;
        overflow: hidden;
        width: 1200px !important;
        //max-width: 100% !important;
        min-height: 600px;
    }
    .form-container {
        position: absolute;
        top: 0;
        height: 100%;
        transition: all 0.6s ease-in-out;
    }
    .sign-in-container {
        left: 0;
        width: 50%;
        z-index: 2;
        background: $primaryColor;
        //background: rgb(21, 12, 86);
        //background: linear-gradient(117deg, rgba(21, 12, 86, 1) 0%, rgba(45, 33, 139, 1) 100%);
        //background: linear-gradient(135deg, rgba(112, 92, 130, 1) 0%, rgba(167, 130, 201, 1) 100%);
    }
    .container.right-panel-active .sign-in-container {
        transform: translateX(100%);
    }
    .sign-up-container {
        left: 0;
        width: 50%;
        opacity: 0;
        z-index: 1;
    }
    .container.right-panel-active .sign-up-container {
        transform: translateX(100%);
        opacity: 1;
        z-index: 5;
        animation: show 0.6s;
    }
    @keyframes show {
        0%,
        49.99% {
            opacity: 0;
            z-index: 1;
        }
        50%,
        100% {
            opacity: 1;
            z-index: 5;
        }
    }
    .overlay-container {
        position: absolute;
        top: 0;
        left: 50%;
        width: 50%;
        height: 100%;
        overflow: hidden;
        transition: transform 0.6s ease-in-out;
        z-index: 100;
    }
    .container.right-panel-active .overlay-container {
        transform: translateX(-100%);
    }
    .overlay {
        //background: #ff416c;
        //background: -webkit-linear-gradient(to right, #ff4b2b, #ff416c);
        //background: linear-gradient(to right, #ff4b2b, #ff416c);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 0;
        color: #ffffff;
        position: relative;
        left: -100%;
        height: 100%;
        width: 200%;
        transform: translateX(0);
        transition: transform 0.6s ease-in-out;
    }
    .container.right-panel-active .overlay {
        transform: translateX(50%);
    }
    .overlay-panel {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 40px;
        text-align: center;
        top: 0;
        height: 100%;
        width: 50%;
        transform: translateX(0);
        transition: transform 0.6s ease-in-out;
    }
    .overlay-left {
        transform: translateX(-20%);
    }
    .container.right-panel-active .overlay-left {
        transform: translateX(0);
    }
    .overlay-right {
        right: 0;
        transform: translateX(0);
    }
    .container.right-panel-active .overlay-right {
        transform: translateX(20%);
    }
    .social-container {
        margin: 20px 0;
    }
    .social-container a {
        border: 1px solid #dddddd;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 0 5px;
        height: 40px;
        width: 40px;
    }
    footer {
        background-color: #222;
        color: #fff;
        font-size: 14px;
        bottom: 0;
        position: fixed;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 999;
    }
    footer p {
        margin: 10px 0;
    }
    footer i {
        color: red;
    }
    footer a {
        color: #3c97bf;
        text-decoration: none;
    }

    //
    .loginImg {
        width: 70%;
    }
    .mailInput {
        background: url(../../../assets/images/myImages/LoginUserIcon.svg) no-repeat left 11px / 24px !important;
        padding-left: 2.2rem !important;
        border-radius: 0 !important;
        &:focus {
            box-shadow: none !important;
            border-bottom: 1px solid $secondaryColor;
        }
    }
    .pwInput {
        background: url(../../../assets/images/myImages/passwordNew.svg) no-repeat left 11px / 24px !important;
        padding-left: 2.2rem !important;
        border-radius: 0 !important;
        &:focus {
            box-shadow: none !important;
            border-bottom: 1px solid $secondaryColor;
        }
    }
    .forgetPw {
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
    input:-webkit-autofill-selected {
        background-color: red !important;
    }
}
input:auto-fill {
    outline: 5px solid red;
    background-color: red !important;
}
input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: -internal-light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important;
    color: fieldtext !important;
}
/* input:-webkit-autofill:hover{
   
    background:#fff url(../images/person.svg) no-repeat left 11px / 24px !important;
} */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    background: #fff url(../../../assets/images/myImages/LoginUserIcon.svg) no-repeat left 11px / 24px !important;
    /* background: url(../images/person.svg) no-repeat left 11px / 24px !important; */
    transition: background-color 5000s ease-in-out 0s;
}

;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:236");