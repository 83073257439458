@use "sass:meta" as ---5bjxerk8lwt;.IconUpload{
    img{
        width: 100px; 
    }
}
.songListTable{
    table{
        tr{
            td{
                &:last-child {
                    display: none;
                }                
            }
            th{
                &:last-child {
                    display: none;
                }               
               
            }
        }
        
    }
    .elipsisText{
        width:88px !important;
    }

}
;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:201");