@use "sass:meta" as ---5bjxerk8lwt;.adminSideBar{
    width:270px;
    max-width:270px;
}
.logo-container{
    width:270px;
    max-width:270px;
    img{
        width: 150px;
        height: 60px;
    }
}
.header-content{
        .menuIcon{
            img{
                width: 40px;
            }
           
        }
}
.menu-item{
    .menu-icons{
        padding-right: 10px;
        img{
            width: 20px;
        }
        
    }
    a{
        color: $adminprimaryColor;
        font:15px $font-regular;
        text-decoration: none;
        width: 100%;
            display: inline-block;
            padding: 12px;
    }
   
    .admin-dropdown{
        ul{
            padding-left:40px;
            li{
                padding: 12px 0 ;
                &:first-child{
                    padding-top: 0;
                }
            }
        }
    }
}
.menu-item.active{
    
    font:15px $font-semibold;
    a{
        color: $adminthemebg;
        font:15px $font-semibold;
    }
    .admin-dropdown{
        ul{
            
            li{
                font:15px $font-semibold;
            }
        }
    }
}
.adminMainContent{
    background:#f2f3f8;
    overflow: auto;
}
.menu-container{
    .accordion{
        background-color: $adminthemebg;
        .accordion-item{
            background-color: $adminthemebg;
            border-bottom: 1px solid #162958;
            &:first-child{
                .accordion-button{
                    &::after{
                        background: none;
                    }
                }
                .accordion-button[aria-expanded="true"]{
                    background: $adminprimaryColor;
                    color: $themebg;
                    .menu-item{
                        font:15px $font-semibold !important;
                    }
                   
                }
            }
           
            .accordion-button{
                background-color: transparent;
                color:$adminprimaryColor;
                font:15px $font-semibold;
                &:focus{
                    border: none;
                    box-shadow: none;
                }
                &::after{
                    
                    background: url(../../../assets/images/myImages/admin-drop.svg);
                    background-repeat: no-repeat;
                }
            }
        }
        .accordion-body{
            padding: 0;
            .admin-dropdownmenu{
                margin: 0;
                li{
                    padding: 20px 0px 20px 57px;
                    color: $adminprimaryColor;
                    cursor: pointer;
                    border-top: 1px solid #162958;
                }
                li.active{
                    background:$adminprimaryColor;
                    color: $adminthemebg;
                    font-family: $font-semibold;
                }

            }
        }
    }
}
;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:197");