@use "sass:meta" as ---5bjxerk8lwt;// scss-docs-start stacks
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}
// scss-docs-end stacks

;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:152");