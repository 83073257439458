@use "sass:meta" as ---5bjxerk8lwt;.normal-custom_check {
  margin-bottom: 15px;
  .control {
    display: block;
    position: relative;
    padding-left: 24px;
    font: 16px $font-regular;
    color: $font-color;
    cursor: pointer;
  }
  .control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  .control__indicator {
    position: absolute;
    top: 4px;
    left: 0;
    height: 19px;
    width: 19px;
    border: 1px solid #ccc;
    background: #fff;
    @include border-radius(5px);
  }
  .control:hover input ~ .control__indicator,
  .control input:focus ~ .control__indicator {
    background: #fff;
  }
  .control input:checked ~ .control__indicator {
    background: $primaryColor;
    border-color: transparent;
  }
  /*.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
  background: #0e647d;
}*/
  .control input:disabled ~ .control__indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
  }
  .control__indicator:after {
    content: "";
    position: absolute;
    display: none;
  }
  .control input:checked ~ .control__indicator:after {
    display: block;
  }
  .control--checkbox .control__indicator:after {
    left: 6px;
    top: 1px;
    width: 6px;
    height: 11px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  .control--checkbox input:disabled ~ .control__indicator:after {
    border-color: #7b7b7b;
  }
  .control--radio .control__indicator:after {
    left: 7px;
    top: 7px;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: #fff;
  }
  .control--radio input:disabled ~ .control__indicator:after {
    background: #7b7b7b;
  }
  .control--radio .control__indicator {
    border-radius: 50%;
  }
}

// custom check box
.custom_check {
  margin-bottom: 15px;
  width: 100%;
  .control {
    display: block;
    position: relative;
    font: 16px $font-regular;
    color: $font-color;
    cursor: pointer;
  }
  .control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  .control__indicator {
    display: inline-block;
    height: 20px;
    width: 100%;
    border: 1px solid #ccc;
    background: #fff;
    @include border-radius(2px);
  }
  .control:hover input ~ .control__indicator,
  .control input:focus ~ .control__indicator {
    background: #fff;
  }
  .control input:checked ~ .control__indicator {
    background: $primaryColor;
    border-color: transparent;
  }
  /*.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
  background: #0e647d;
}*/
  .control input:disabled ~ .control__indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
  }
  /* .control__indicator:after {
  content: '';
  position: absolute;
  display: none;
} */
  .control input:checked ~ .control__indicator:after {
    display: block;
  }
  /* .control--checkbox .control__indicator:after {
     left: 6px;
    top: 1px;
    width: 6px;
    height: 11px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
} */
  .control--checkbox input:disabled ~ .control__indicator:after {
    border-color: #7b7b7b;
  }
  .control--radio .control__indicator:after {
    left: 7px;
    top: 7px;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: #fff;
  }
  .control--radio input:disabled ~ .control__indicator:after {
    background: #7b7b7b;
  }
  .control--radio .control__indicator {
    border-radius: 50%;
  }
}

// custom radio

.custom_radio {
  margin: 20px 0 0;
  .control {
    display: block;
    position: relative;
    padding-left: 44px;
    cursor: pointer;
    font: 16px $font-regular;
    color: $font-color;
  }
  .control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  .control__indicator {
    position: absolute;
    top: 1px;
    left: 0;
    height: 24px;
    width: 24px;
    border: 1px solid #ccc;
    background: #fff;
  }
  .control:hover input ~ .control__indicator,
  .control input:focus ~ .control__indicator {
    background: #fff;
  }
  .control input:checked ~ .control__indicator {
    background: #fff;
  }
  /*.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
  background: #0e647d;
}*/
  .control input:disabled ~ .control__indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
  }
  .control__indicator:after {
    content: "";
    position: absolute;
    display: none;
  }
  .control input:checked ~ .control__indicator:after {
    display: block;
  }
  .control--checkbox .control__indicator:after {
    left: 6px;
    top: 1px;
    width: 6px;
    height: 11px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  .control--checkbox input:disabled ~ .control__indicator:after {
    border-color: #7b7b7b;
  }
  .control--radio .control__indicator:after {
    left: 7px;
    top: 7px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: $primaryColor;
  }
  .control--radio input:disabled ~ .control__indicator:after {
    background: #7b7b7b;
  }
  .control--radio .control__indicator {
    border-radius: 50%;
  }
}
//  custom switch button

.cmn-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.cmn-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input.cmn-toggle-round + label {
  padding: 2px;
  width: 55px;
  height: 30px;
  background-color: #dddddd;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
}
input.cmn-toggle-round + label:before,
input.cmn-toggle-round + label:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: "";
}
input.cmn-toggle-round + label:before {
  right: 1px;
  background-color: #f1f1f1;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round + label:after {
  width: 30px;
  background-color: #fff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  -webkit-transition: margin 0.4s;
  -moz-transition: margin 0.4s;
  -o-transition: margin 0.4s;
  transition: margin 0.4s;
}
input.cmn-toggle-round:checked + label:before {
  background-color: $primaryColor;
}
input.cmn-toggle-round:checked + label:after {
  margin-left: 22px;
}
.switch_control {
  .cmn-toggle {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
  }
  .cmn-toggle + label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  input.cmn-toggle-round + label {
    padding: 2px;
    width: 67px;
    height: 34px;
    background-color: #dddddd;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
  }
  input.cmn-toggle-round + label:before,
  input.cmn-toggle-round + label:after {
    display: block;
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    content: "";
  }
  input.cmn-toggle-round + label:before {
    right: 1px;
    background-color: #f1f1f1;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    -webkit-transition: background 0.4s;
    -moz-transition: background 0.4s;
    -o-transition: background 0.4s;
    transition: background 0.4s;
  }
  input.cmn-toggle-round + label:after {
    width: 30px;
    background-color: #fff;
    -webkit-border-radius: 0%;
    -moz-border-radius: 0%;
    -ms-border-radius: 0%;
    -o-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-transition: margin 0.4s;
    -moz-transition: margin 0.4s;
    -o-transition: margin 0.4s;
    transition: margin 0.4s;
  }
  input.cmn-toggle-round:checked + label:before {
    background-color: $primaryColor;
  }
  input.cmn-toggle-round:checked + label:after {
    margin-left: 35px;
  }
}

.uploadBtn {
  font: 18px $font-regular;
  color: #fff;
  padding: 8px 27px;
  background: transparent;
  border: none;
  &:hover {
    color: #fff;
    background: transparent;
    outline: none;
  }
  &:focus {
    color: #fff;
    outline: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
}
.yellowBtn {
  display: inline-block;
  padding: 6px 25px;
  background: $secondaryColor;
  font: 18px $font-regular;
  text-decoration: none;
  color: #000000;
  @include border-radius(5px);
  &:hover {
    color: #000000;
    text-decoration: none;
  }
}
.submitBtn {
  display: inline-block;
  padding: 6px 25px;
  //background: $primaryColor;
  font: 18px $font-regular;
  text-decoration: none;
  color: $secondaryColor;
  cursor: pointer;
  border: none;
  @include border-radius(5px);
  /*  &:hover{
    color: $themebg;
    background: $secondaryColor;
    text-decoration: none;
  } */
  background: linear-gradient(to left, $primaryColor 50%, $secondaryColor 50%) right;
  background-size: 200%;
  transition: 0.5s ease-out !important;
  &:hover {
    background-position: left;
    border: 0 !important;
    color: #fff !important;
    text-decoration: none;
  }
}
.btnCustom {
  font: 18px $font-regular !important;
  width: 100% !important;
  border: none !important;
  border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
}

.kep-login-facebook.metro {
  background: #3b5998;
  color: #fff;
  font: 18px $font-regular;
  padding: 10px 25px;
  width: 100%;
  border: none;
  text-transform: capitalize !important;
  border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
}
.googleBtn {
  border: 1px solid #bebebe !important;
  color: #ea4335 !important ;
  box-shadow: none !important;
  span {
    margin: 0 auto;
  }
}
.googleBtn div {
  position: absolute;
}
.EmailBtn {
  border: 1px solid #bebebe;
  color: #383938;
}
.socialIcon {
  float: left;
}
.themeBtn {
  display: inline-block;
  padding: 10px 25px;
  background: $primaryColor;
  font: 18px $font-regular;
  text-decoration: none;
  color: $primaryColor;
  @include border-radius(5px);
  &:hover {
    color: $primaryColor;
    text-decoration: none;
  }
}

.normal_custom_check {
  margin-bottom: 15px;
  .control {
    display: block;
    position: relative;
    padding-left: 30px;
    font: 16px $font-regular;
    color: $font-color;
    cursor: pointer;
  }
  .control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  .control__indicator {
    position: absolute;
    top: 4px;
    left: 0;
    height: 19px;
    width: 19px;
    border: 1px solid #ccc;
    background: #fff;
    @include border-radius(5px);
  }
  .control:hover input ~ .control__indicator,
  .control input:focus ~ .control__indicator {
    background: #fff;
  }
  .control input:checked ~ .control__indicator {
    background: $primaryColor;
    border-color: transparent;
  }
  /*.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
  background: #0e647d;
}*/
  .control input:disabled ~ .control__indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
  }
  .control__indicator:after {
    content: "";
    position: absolute;
    display: none;
  }
  .control input:checked ~ .control__indicator:after {
    display: block;
  }
  .control--checkbox .control__indicator:after {
    left: 6px;
    top: 1px;
    width: 6px;
    height: 11px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  .control--checkbox input:disabled ~ .control__indicator:after {
    border-color: #7b7b7b;
  }
  .control--radio .control__indicator:after {
    left: 7px;
    top: 7px;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: #fff;
  }
  .control--radio input:disabled ~ .control__indicator:after {
    background: #7b7b7b;
  }
  .control--radio .control__indicator {
    border-radius: 50%;
  }
}

.adminThemebtn {
  display: inline-block;
  padding: 10px 25px;
  //background: $adminthemebg;
  font: 18px $font-regular;
  text-decoration: none;
  border: none;
  color: $secondaryColor;
  @include border-radius(5px);
  /* &:hover{
    color: $light_color;
    text-decoration: none;
  } */
  background: linear-gradient(to left, $adminthemebg 50%, $secondaryColor 50%) right;
  background-size: 200%;
  transition: 0.5s ease-out !important;
  &:hover {
    background-position: left;
    border: 0 !important;
    color: #fff !important;
    text-decoration: none;
  }
}

;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:219");