@use "sass:meta" as ---5bjxerk8lwt;.adminAccessUserBase {
    div:first-child {
        white-space: normal !important;
        word-wrap: break-word !important;
        //width: 220px;
    }
    .privilege-bg {
        background-color: $adminthemebg !important;
        padding: 7px 19px !important;
        font-size: 13px;
    }
}

;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:212");