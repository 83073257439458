@use "sass:meta" as ---5bjxerk8lwt;.Imagestyle {
    height: 100px;
    width: 80px;
    object-fit: cover;
}

.userImagestyle {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 100px !important;
}

.blockImg {
    height: 100px;
    width: 100px;
    object-fit: contain;
}

;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:232");