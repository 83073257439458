@use "sass:meta" as ---5bjxerk8lwt;.giftcoins{
     div:first-child {
        white-space: nowrap;
        overflow: inherit !important;
        text-overflow: ellipsis;
        font-size: 16px;
    } 
    .adminTable {

        .normal-custom_check .control__indicator {
            position: absolute;
            top: -18px !important;
            left: 0;
            height: 19px;
            width: 19px;
            border: 1px solid #ccc;
            background: #fff;
            border-radius: 5px;
        }
    }
}
;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:208");