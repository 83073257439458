@use "sass:meta" as ---5bjxerk8lwt;.duet-check{
    width: 100%;
    li{
        width: 48%;
    }
}
.dutesTabBase{
    width: 100%;
    .nav-item {
        width: 48%;
        text-align: center;
        a{ 
            cursor: pointer;
        }
    }
}
.nav-pills.dutesTabBase .nav-link.active, .nav-pills .show>.nav-link {
    color: #000;
    background-color: transparent;
    border-color: $primaryColor;
}
.nav-pills.dutesTabBase .nav-link, .nav-pills .show>.nav-link {
    color: $primaryColor;
    border-bottom: 4px solid transparent;
    background-color: transparent;
    border-radius:0;
}
.tabContentDuet{
    width: 100%;
    margin-top: 20px;
    
        li{
            width: 48%;
            text-align: center;
        }

}
.audioContainerBase {
    background-color: $primaryColor;
    position: fixed;
        bottom: 0;
        width: 100%;
    
    .rhap_container  {
        background-color: $primaryColor;
        color: $themebg;
        border-radius: 0;
        border:none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
      .rhap_time{
          color: $secondaryColor;
      }
      .rhap_progress-indicator,.rhap_progress-filled,.rhap_volume-bar{
          background-color: $secondaryColor;
      }
      .rhap_volume-button,.rhap_main-controls{
          svg{
              path{
                fill: $secondaryColor !important;
              }
              
          }
      }
      .rhap_additional-controls{
          display: none;
      }
  
}


audio::-webkit-media-controls-play-button{
   background-color: #fff;
  color: #fff;
}

.groupSong{
    
    li{
        width: 20%;
        margin-right: 5px !important;
    }
}

.group-check{
    width: 100%;
    li{
        width: 20%;
    }
}
.lyricsBaseWeb{
    margin-top: 7% !important;
}
.empty-height{
    line-height: 28px;
}
;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:179");