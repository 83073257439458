@use "sass:meta" as ---5bjxerk8lwt;.PrivacyBase {
    /*  background: #0a193d; */
    background: #fff;

    /*  h1{
        font-size:26px ;
        padding-bottom:10px
       
    } */
    h2 {
        font-size: 21px;
        padding-top: 30px;
        padding-bottom: 20px;
    }

    h3 {
        font-size: 24px;
        padding-bottom: 23px;
    }

    p {
        font-size: 18px
    }
}
;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:177");