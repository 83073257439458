@use "sass:meta" as ---5bjxerk8lwt;.last-child-hide {
    table {
        tr {
            td {
                &:last-child {
                    display: none;
                }
            }
            th {
                &:last-child {
                    display: none;
                }
            }
        }
    }
}
.admin-profile-banner {
    img {
        height: 350px;
        object-fit: cover;
    }
    .admin-profilepic {
        bottom: 10%;
        left: 45%;

        img {
            width: 100px;
            height: 100px;
            @include border-radius(50%);
        }
    }
}

.admin-profile-base {
    .input-control {
        background-color: #f2f3f8;
    }
    .select-control {
        background-color: #f2f3f8;
    }
    table {
        tr {
            background-color: #f2f3f8;
            td {
                background-color: #f2f3f8;
            }
        }
    }
}
.reportcheck {
    /* .form-check-input:checked {
        background: #0a193d !important;
    } */
    /*    .form-check-input:checked[type="checkbox"] {
        left: 6px !important;
        top: 1px !important;
        width: 6px !important;
        height: 11px !important;
        border: solid #fff !important;
        border-width: 0 2px 2px 0 !important;
        transform: rotate(45deg) !important;
        position: absolute;
    } */
    .form-check-input {
        margin-left: -1.2em !important;
        margin-right: 0.7rem !important;
        /* &:checked{

            background: #0a193d !important;
            border-color: transparent !important;
        } */
        /* &::after {
            left: 6px !important;
            top: 1px !important;
            width: 6px !important;
            height: 11px !important;
            border: solid #fff !important;
            border-width: 0 2px 2px 0 !important;
            transform: rotate(45deg) !important;
        } */
    }
}
.reportuser {
    button {
        &:focus {
            box-shadow: none !important;
            outline: none !important;
        }
        &:focus-visible {
            box-shadow: none !important;
            outline: none !important;
        }
    }

    .dtqzBx div:first-child {
        white-space: nowrap;
        overflow: inherit !important;
        text-overflow: ellipsis;
        font-size: 16px;
    }
    .chdMBN div:first-child {
        overflow: inherit !important;
    }
    .reportCheck {
        .control__indicator {
            position: absolute;
            top: -18px !important;
            left: 0;
            height: 19px;
            width: 19px;
            border: 1px solid #ccc;
            background: #fff;
            border-radius: 5px;
        }
    }
}

;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:205");