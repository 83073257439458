@use "sass:meta" as ---5bjxerk8lwt;.IconUpload {
    img {
        width: 100px;
    }
}
.stickerCattable {
    table {
        tr {
            td {
                &:last-child {
                    display: none;
                }
                &:nth-last-child(2) {
                    display: none;
                }
            }
            th {
                &:last-child {
                    display: none;
                }
                &:nth-last-child(2) {
                    display: none;
                }
            }
        }
    }
    .pub {
        border-radius: 5px;
    }
}
.cmsBase {
    .unpublished-bg {
        background-color: #dc3545 !important;
        padding: 7px 12px !important;
        font-size: 13px;
        color: #fff;
        border-radius: 5px;
        &:hover{
            cursor: pointer;
        }
    }
    .published-bg {
        background-color: #198754 !important;
        padding: 7px 21px !important;
        font-size: 13px;
        color: #fff;
        border-radius: 5px;
        &:hover {
                cursor: pointer;
            }
    }
    .danger-bg {
        padding: 7px 14px !important;
    }
}

;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:218");