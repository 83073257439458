@use "sass:meta" as ---5bjxerk8lwt;//
// Form text
//

.form-text {
  margin-top: $form-text-margin-top;
  @include font-size($form-text-font-size);
  font-style: $form-text-font-style;
  font-weight: $form-text-font-weight;
  color: $form-text-color;
}

;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:74");