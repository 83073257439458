@use "sass:meta" as ---5bjxerk8lwt;// scss-docs-start clearfix
@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
// scss-docs-end clearfix

;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:44");