@use "sass:meta" as ---5bjxerk8lwt;
$font-regular:PoppinsRegular;
$font-bold:PoppinsBold;
$font-light:PoppinsLight;
$font-semibold:PoppinsSemiBold;
$primaryColor:#0a193d;
$secondaryColor: #ffb301;
$sidebarAct:#333d57;
$themebg: #F1F5F9;
$adminthemebg:#0a193d;
$adminprimaryColor:#ffb301;
$footerBg:#0d1e48;
$greybg:#f7f7f7;
$head-color:#474a4d;
$light_color: #ffff;
$font-color:#333333;
$left:left;
$right:right;
$left_abs:left;
$right_abs:right;
$blk: #000;
$dangerColor: red;
$next-color: #1DB81D;
$themeTxt:#7f6696;
$btnHover:#3b4d78;



//pagination
$pagination-active-color:$adminthemebg;
$pagination-active-bg: $adminprimaryColor;
$pagination-active-border-color: $adminprimaryColor;
$pagination-color: $adminprimaryColor;
$pagination-bg: $adminthemebg;
$pagination-border-color: $adminthemebg;
$pagination-focus-color:  $adminthemebg;
$pagination-focus-bg:   $adminprimaryColor;
$pagination-hover-color: $adminthemebg;
$pagination-hover-bg:  $adminprimaryColor;
$pagination-hover-border-color:$adminprimaryColor;
$container-max-widths: (
  sm: 540px,
  md: 100%,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:0");