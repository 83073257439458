@use "sass:meta" as ---5bjxerk8lwt;.demoBase {

    .profileHead {

        flex-direction: row;

        display: flex;

        .profileimg {
            width: 120px;
            height: 120px;
            border-Radius: 60px;
            margin-Top: 60px
        }

        .profileNameHead {
            margin-Top: 70px;
            margin-left: 30px
        }
    }



    .profilesmlimg {
        width: 60px;
        height: 60px;
        border-Radius: 40px;

    }


    .tabHead {
        margin-Top: 20px;
        margin-left: 70px
    }


    .color {
        background-color: #D2D7D5
    }

}
;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:230");