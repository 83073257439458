@use "sass:meta" as ---5bjxerk8lwt;.stepToUpload {
    background: $primaryColor;
    .stepsList {
        font: 16px $font-regular;
        color: #9c9c9c;
        li {
            padding-right: 60px;
            position: relative;
            -webkit-transition: all 1.5s ease-in-out;
            -moz-transition: all 1.5s ease-in-out;
            -o-transition: all 1.5s ease-in-out;
            transition: all 1.5s ease-in-out;
            .animatedLine {
                background: #9c9c9c;
                height: 3px;
                width: 0;
                display: inline-block;
                top: 12px;
                left: -63px;
                position: absolute;
                -webkit-transition: all 1.5s ease-in-out;
                -moz-transition: all 1.5s ease-in-out;
                -o-transition: all 1.5s ease-in-out;
                transition: all 1.5s ease-in-out;
            }
        }
        li.active {
            color: $secondaryColor;
        }
        li.active + li .animatedLine {
            background: $secondaryColor;
            width: 50px;
        }
    }
}

.songUpload {
    h1 {
        font: 36px $font-regular;
        color: $primaryColor;
    }
    h2 {
        font: 18px $font-semibold;
        color: $primaryColor;
    }
    h3 {
        font: 18px $font-semibold;
        color: $primaryColor;
    }
    .songUploadInner {
        border: 1px solid #cecece;
        @include border-radius(4px);
    }
    #upload-photo {
        opacity: 0;
        position: absolute;
        z-index: -1;
    }
    .songUpload {
        cursor: pointer;
        width: 220px;
    }
    .upload-container {
        width: 50%;
        display: inline-block;
    }
}

;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:226");