@use "sass:meta" as ---5bjxerk8lwt;/* .tagColor
{    background: red;
    border-radius: 5px;
    color: white;
    padding: 5px;
} */
.langInp {
    border: 1px solid #b1b7c1 !important;
    border-radius: 23px !important;
}
.musicDetailsBase {
    .langInp {
        font: 16px PoppinsRegular;
        color: #2c384af2;
    }
    .uploadImg {
        width: 120px;
        height: 120px;
        object-fit: cover;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
}
}

;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:185");