@use "sass:meta" as ---5bjxerk8lwt;.selectTiming{
    padding: 20px;
    margin: 10px 0px;
    cursor: pointer;
    border-top: 1px solid transparent ;
        border-bottom: 1px solid transparent ;
    &:hover{
        border-top: 1px solid $adminprimaryColor ;
        border-bottom: 1px solid $adminprimaryColor ;
    }
}
.activeLine{
    background-color: $adminthemebg;
    color: $adminprimaryColor;
    font: 18px $font-bold;
}

;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:182");