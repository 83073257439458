@use "sass:meta" as ---5bjxerk8lwt;
/* .sidebar-nav .dashboard-Nav-link,
.simplebar-content .dashboard-Nav-link:hover {
  color: #fff !important;
}
.sidebar-nav .dashboard-Nav-link .nav-icon,
.simplebar-content .dashboard-Nav-link .nav-icon:hover {
  color: #fff !important;
} */
.sidebar {
  background-color: $primaryColor !important;
  color: #fff !important;
  ::-webkit-scrollbar {
    display: none !important;
  }
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 0px !important;
}

.sidebar-nav {
  .nav-icon {
    color: $greybg !important;
  }

  li {
    &:hover {
      //color: $light_color !important;
    }
  }
}

.sidebar-brand {
  background-color: $primaryColor !important;
}

.simplebar-content {
  .nav-link {
    color: #fff !important;
  }
  .nav-link:hover {
    color: #000 !important;
    .nav-icon {
      color: $blk !important;
    }
  }

  li {
    .nav-link.active {
      background-color: $themebg !important;
    }
  }
  /* li:first-child .nav-link .active{
        background-color: $themebg !important;
    } */
  .nav-group {
    .nav-link {
      margin-bottom: 0 !important;
    }
  }
  .nav-group-items {
    li:first-child .nav-link {
      margin-top: 0px !important;
      background-color: transparent !important;
    }
    li:last-child .nav-link {
      margin-bottom: 0px !important;
    }
  }

  li {
    position: relative !important;
    margin: 10px 0px 10px 0px !important;

    .nav-icon {
      color: $light_color !important;
      &:hover {
        color: #000 !important;
      }
    }

    &:hover {
      /*   background-color: $themebg !important; */
      margin: 10px 0px 10px 0px !important;
      border-radius: 25px 0px 0px 25px !important;

      .nav-icon {
        //color: $blk !important;
      }
    }

    &:active {
      /*   background-color: $themebg !important; */
      margin: 10px 0px 10px 0px !important;
      border-radius: 25px 0px 0px 25px !important;
      //background-color: #f2f3f8 !important;
      color: #fff !important;

      .nav-item {
        &:hover {
          margin: 10px 0px 10px 0px !important;
        }
      }

      .nav-icon {
        //color: $blk !important;
      }
    }

    a:hover {
      //color: $blk !important;
      font-size: 16px !important;
    }
  }
}

.header {
  background-color: $themebg !important;
  margin-right: 20px !important;
  border-radius: 25px 25px 0 0 !important;
}

.header-divider {
  height: 0 !important;
  margin: 0 !important;
  border-top: 0 !important;
}

.icon {
  color: $primaryColor !important;
}

.header-nav .nav-link {
  color: $primaryColor !important;
}

.sidebar-brand {
  margin-left: 15px;
  margin-right: 15px;
}

.sidebar-nav .nav-group.show {
  a {
    color: $greybg !important;
  }

  .nav-link.active {
    color: $adminthemebg !important;
    background-color: transparent !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;

    .nav-icon {
      color: $adminthemebg !important;
    }

    &:after {
      content: "";
      width: 0px !important;
      height: 0px !important;
    }

    &:before {
      content: "";
      width: 0px !important;
      height: 0px !important;
    }
    &:hover {
      color: $adminthemebg !important;
    }
  }

  .nav-link {
    &:hover {
      color: $light_color !important;
      background-color: transparent !important;
      border-radius: 25px;
      .nav-icon {
        color: $primaryColor !important;
      }
    }
  }

  background-color: $secondaryColor !important;
  border-radius: 25px !important;
  margin: 4px 9px 4px 9px !important;

  .nav-group-toggle {
    color: $light_color !important;
  }

  &:after {
    content: "";
    background-color: #262450 !important;
  }

  &:before {
    content: "";
    background-color: #262450 !important;
  }
}

.sidebar-nav {
  ::-webkit-scrollbar {
    display: none !important;
  }
  li:first-child {
    .nav-link {
      margin-top: 43px !important;
    }
    .nav-link.active {
      &:after {
        margin-top: 50px;
      }
      &:before {
        top: -17px;
        right: 20px;
      }
    }
  }
  .nav-item:last-child {
    .nav-link {
      margin-bottom: 46px !important;
    }
  }

  .nav-link.active {
    color: $blk;
    margin-top: 13px !important;
    margin-bottom: 13px !important;
    background-color: transparent !important;
    border-radius: 25px 0 0 25px !important;
    font-size: 16px !important;
    margin-right: 0 !important;
    width: 260px !important;
    //background: #f2f3f8 !important;
    border-radius: -1% !important;

    &:hover {
      //background-color: #f2f3f8 !important;
      width: 269px !important;
    }

    .nav-icon {
      color: $blk;
    }

    &:after {
      content: "";
      width: 56px !important;
      height: 56px !important;
      margin-top: 49px;
      transform: scale(1.04) !important;
      background-size: 100% !important;
      /* background-image: url("../../assets/images/download.svg") !important; */
      position: absolute !important;
      top: 1px !important;
      right: 20px !important;
      margin-right: -1.25rem !important;
    }

    &:before {
      content: "";
      width: 46px !important;
      height: 46px !important;
      margin-top: -29px !important;
      transform: rotate(90deg) scale(1.04) !important;
      background-size: 100% !important;
      /* background-image: url("../../assets/images/download.svg") !important; */
      position: absolute !important;
      top: -17px;
      right: 20px;
      margin-right: -1.25rem !important;
    }
    .nav-item {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .nav-link {
      color: $light_color !important;
      font-size: 16px;
      width: 260px;
      &:hover {
        .nav-item {
          margin-top: 0 !important;
          margin-bottom: 0 !important;
        }
        background: transparent !important;
        //border-radius: 25px 0px 0px 25px !important;
        border-radius: 37px !important;
        margin-right: 10px !important;
      }
    }
  }
}
//footer-arrow
.sidebar-toggler::before {
  display: none !important;
}

.dashboard-Nav-link {
  color: #fff !important;
}
.dashboard-Nav-link.active {
  color: #fff !important;
}

;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:231");