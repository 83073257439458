@use "sass:meta" as ---5bjxerk8lwt;.sign_in_inputs input[type=email] {
    background: url(../../../assets/images/myImages/mail.svg);
    background-repeat: no-repeat;
    background-position: 95%;
    background-size: 20px;
}
.sign_in_inputs input[type=text] {
    background: url(../../../assets/images/myImages/user.svg);
    background-repeat: no-repeat;
    background-position: 96%;
    background-size: 26px;
}
.sign_in_inputs input[type=password] {
    background: url(../../../assets/images/myImages/lock.svg);
    background-repeat: no-repeat;
    background-position: 95%;
    background-size: 20px;
}
.sign_in_inputs input {
    border: 1px solid #d1d1d1;
    width: 100%;
    margin: 10px 0;
    padding: 0 45px 0 15px;
    height: 43px;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    font: 14px $font-regular;
    color: #383838;
}
.modal-title{
    font: 18px $font-semibold;
    color:$themebg;
}
.error{
    font: 11px $font-semibold;
    color:#f00;
    padding: 0%;
}
;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:172");