@use "sass:meta" as ---5bjxerk8lwt;.awardsListBase {
    /* .profBase {
        display: inline-block;
        border-radius: 50%;
        overflow: hidden;
        width: 100px;
    }
    .singerprof {
        width: 100%;
        display: block;
    }
    .profBase:not(:first-child) {
        margin-left: -60px;
        -webkit-mask: radial-gradient(circle 55px at 5px 50%, transparent 99%, #fff 100%);
        mask: radial-gradient(circle 55px at 5px 50%, transparent 99%, #fff 100%);
    }
    .chdMBN div:first-child {
        white-space: normal !important;
    } */
    .singerprof{
        width: 55px;
        height: 55px;
        object-fit: cover;
        border-radius: 50%;
    }
    .react-date-picker{
        cursor: pointer;
    }
    .react-date-picker__wrapper {
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        border: thin solid gray;
        border-radius: 5px;
        padding: 3px 3px;
        cursor: pointer;
    }
    .react-date-picker__calendar-button {
        display: none;
    }
    .react-date-picker__inputGroup {
        padding-right: 50px !important;
        background-image: url(../../../assets/images/myImages/calender.png);
        background-repeat: no-repeat;
        background-position: 96% 1px;
        background-size: 25px;
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -ms-transition: all 1s ease;
        -o-transition: all 1s ease;
        transition: all 1s ease;
    }
    .react-date-picker--open .react-date-picker__inputGroup {
        background-position: 96% -30px;
    }
}

;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:215");