@use "sass:meta" as ---5bjxerk8lwt;.adminLanding{
    
    h1{
        font: 36px $font-light;
        color: #fff;
    }
    background:$primaryColor url(../../../assets/images/myImages/landbg.png);
    background-repeat: no-repeat;
    background-position:bottom;
}
;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:194");