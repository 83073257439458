@use "sass:meta" as ---5bjxerk8lwt;* {
    font-family: $font-regular;
}
.nopadd {
    padding: 0 !important;
}

img {
    max-width: 100%;
}
input {
    &:focus {
        outline: none;
    }
}
textarea {
    &:focus {
        outline: none;
    }
}
::-webkit-scrollbar {
    display: none !important;
}
.primaryColor {
    color: $primaryColor;
    fill: $primaryColor;
}
.themeColor {
    color: $themebg;
    fill: $themebg;
}
/* .filter-check-items-inner::-webkit-scrollbar {
    width: 4px;
}
.filter-check-items-inner::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
}
.filter-check-items-inner::-webkit-scrollbar-thumb {
    background-color: $themebg;
    border-radius: 10px;
} */
.uploadBtnBase .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    background: url(../../assets/images/myImages/arrow.png);
    content: "";
    border-top: none;
    width: 10px;
    height: 7px;
    position: absolute;
    right: 3px;
    top: 17px;
    background-repeat: no-repeat;
    border-right: none;
    border-bottom: 0;
    border-left: none;
}
.uploadBtnBase .dropdown {
    display: inline-block;
    margin-top: 19px;
}
.joinFree {
    font: 18px $font-regular;
    color: #fff;
    text-decoration: none;
    display: inline-block;
    padding: 8px 31px;
    border: 1px solid #fff;
    @include border-radius(4px);
    &:hover {
        color: #fff;
        text-decoration: none;
    }
}
.input-control {
    padding: 8px;
    border: 1px solid #cecece;
    width: 90%;
    margin: 6px;
    font: 14px $font-regular;
    @include border-radius(4px);
}
.uploadBtnBase {
    .btn-check:active + .btn-primary,
    .btn-check:checked + .btn-primary,
    .btn-primary.active,
    .btn-primary:active,
    .show > .btn-primary.dropdown-toggle {
        background: transparent;
        outline: none;
        border-color: transparent;
        box-shadow: none;
    }
}

.textarea-control {
    width: 100%;
    border: none;
    resize: none;
    line-height: 28px;
}
.f-regular {
    font-family: $font-regular !important;
}
.f-semibold {
    font-family: $font-semibold !important;
}
.f-bold {
    font-family: $font-bold !important;
}
.f-light {
    font-family: $font-light !important;
}

.bodybg {
    background-color: #f3f5f9;
}
.themebg {
    background-color: $themebg;
}
.primarythemebg {
    background-color: $primaryColor;
}

.select-control {
    font: 16px $font-semibold;
    color: #000000;
    @include selectboxnone;
    width: 100%;
    border: none;
    background: #fff url(../../assets/images/myImages/filter-drop.png);
    background-repeat: no-repeat;
    background-position: 97% center;
    padding: 7px 27px 7px 10px;
    @include border-radius(5px);
}

//admin

.adminThemebg {
    background-color: $themebg;
}
.adminTable {
    table {
        border-collapse: separate;
        border-spacing: 0 1em;
        thead {
            tr {
                background: transparent;
            }
        }
        th {
            border: none;
            padding: 15px;
        }
        td {
            border: none;
            padding: 20px 15px;
            background: #fff;
            &:first-child {
                @include border-radius-seprate(5px, 0, 0, 5px);
            }
            &:last-child {
                @include border-radius-seprate(0px, 5px, 5px, 0px);
            }
        }
    }
}
.cursor-pointer {
    cursor: pointer;
}
.dropdown-toggle::after {
    display: inline-block;
    top: 28%;
    position: absolute;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    width: 20px;
    height: 20px;
    background-size: 100% 100% !important;
    content: "";
    background-position: center center;
    border: 0 !important;
    background: url(../../assets/images/myImages/admin-drop.svg);
}
//new-theme-scss
* {
    font-family: $font-regular;
}

/* .font-bold {
    font-family: $font-semibold !important;
} */

.light_color {
    background-color: $light_color;
}

.logoimg {
    width: 141px;
    //height: 50px;
    object-fit: contain;
    padding: 6px 10px 9px 1px;
}

.spinner {
    display: flex !important;
    flex: 1;
    align-items: center !important;
    justify-content: center !important;
}

.accordion-button {
    color: $light_color;
    background-color: $head-color !important;
    border-radius: 25px !important;
}

::-webkit-scrollbar {
    display: none;
}

.primary {
    background-color: $primaryColor;
}

.primarydash {
    background-color: $primaryColor !important;
    color: $light_color !important;
}

.secondary {
    color: $secondaryColor;
}

.themebg {
    background-color: $themebg;
}

.borderradi {
    border-radius: 20px;
}

.borderradiprogress {
    border-radius: 25px;
}

.error {
    color: $dangerColor;
    font-size: 12px;
    margin-left: 10px;
    margin-bottom: 10px;
}

.marginstart {
    margin-left: -50px;
}

.ms-toggle {
    margin-left: -50px;
}

.mstextbox {
    margin-left: 85px;
}

.accordion-item {
    border: 0px !important;
}

.form-select {
    border-radius: 20px !important;
}

.react-datepicker-ignore-onclickoutside {
    border: 0px !important;
    outline: 0px !important;
    input {
        border: 0px !important;
    }
}

.react-datepicker__input-container {
    input {
        border: 0px !important;
    }
}

.form-control {
    border-radius: 20px !important;
}

.margin {
    margin-left: 200px !important;
}

.rdt_TableHeadRow {
    font-size: 17px !important;
    margin-right: 10px !important;
}
.fc-scrollgrid-liquid {
    background-color: #fff;
}
.fc-col-header-cell {
    padding: 10px !important;
}
.fc {
    .fc-daygrid-day-number {
        padding-right: 10px !important;
    }
}

.textellipsis {
    white-space: nowrap;

    overflow: hidden;
    text-overflow: ellipsis !important;
}

.accordionwidth {
    .btn-primary {
        color: $blk !important;
        background-color: $light_color !important;
        width: 100%;

        &:hover {
            background-color: $primaryColor !important;
            color: $light_color !important;
        }
    }

    h5 {
        font-size: 15px;
    }
}

.accordion-button {
    padding: 10px 30px !important;
    background-color: $themebg !important;

    h5 {
        margin-top: 2px !important;
        margin-bottom: 2px !important;
    }
}

.textboxheight {
    height: 40px;
}

.body {
    background-color: $themebg !important;
    margin-right: 20px !important;
    border-radius: 0 0 20px 20px !important;
    margin-bottom: 25px;
}

.defaultWrapper {
    background-color: $primaryColor !important;
}

.marginTop {
    margin-top: -101px !important;
}

.text_box_form {
    border: none !important;
    border-radius: 11px !important;
}

.back {
    margin-top: 20px;
    margin-bottom: 20px;
}

.nav-tabs {
    border-bottom: 0px !important;
    border: 0px !important;

    .nav-link {
        color: #000 !important;
    }
}

.nav-tabs .nav-link:hover {
    background-color: #f2f2f2 !important;
    border: 0px !important;
    border-radius: 0px !important;
    color: #000 !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    background-color: #f2f2f2 !important;
    border: 0px !important;
    border-radius: 0px !important;
    color: #000 !important;
}

.bg {
    background-color: #f2f2f2 !important;
}

.eVPPvF {
    padding-left: 11px !important;
}

.autocomplete {
    .wrapper {
        background-color: #fff !important;
        padding-left: 0 !important;
        position: absolute;
        z-index: 1 !important;
    }
}

.TodoList_width {
    max-width: 61.5%;
}

.Todo_width {
    max-width: 64%;
}
.themetxt {
    color: $secondaryColor !important;
}
.primaryText {
    color: $primaryColor !important;
}

//sidebar-footer
.sidebar-footer {
    &:before {
        background-image: none !important;
    }
}

//datatable
//th
.hbotQl {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 18px;
    font-weight: bold;
}

.dtqzBx div:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
}

.iSAVrt,
.dwPlXY:not(:last-of-type) {
    border-bottom-width: 0.1px !important;
    border-bottom-color: rgba(131, 122, 122, 0.12) !important;
    border-bottom-style: solid;
}
.gKbhqU {
    border-top-width: 0.1px !important;
    border-top-color: rgba(131, 122, 122, 0.12) !important;
    border-top-style: solid;
}
.sc-hLBbgP {
    display: flex;
    justify-content: start;
}
.glyQNg,
.eVPPvF {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.rdt_TableHeadRow,
.rdt_TableRow {
    padding: 10px 10px;
    display: flex;
    align-items: center;
}
.rdt_TableRow {
    font-size: 15px !important;
}
//table-elipsis-text
.elipsisText {
    white-space: nowrap;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}
//bg-sucess
.success-bg{
    background-color: #198754!important;
    padding: 7px 19px !important;
    font-size:13px;
    color: #fff;
}
.danger-bg{
    background-color:#dc3545!important;
    padding: 7px 8px !important;
    font-size:13px;
    color: #fff;
}
.listNames{
    white-space: normal !important;
    word-wrap: break-word !important;
    
}

;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:221");