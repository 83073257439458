@use "sass:meta" as ---5bjxerk8lwt;.dashboardBase{
    .avatar-img{
        width: 100% !important;
        height: 100% !important;
    }
    .table td{
       background-color: #fff;
       height: 78px;
    } 
   /*  .usersTable{
        table{

            min-height: 431px !important;
        }
        
    }
    .songTable{
        table{

            min-height: 431px !important;
        }
    } */
}
;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:216");