@use "sass:meta" as ---5bjxerk8lwt;.footerBase{
    background: $footerBg;
    h2{
        font: 36px $font-light;
        color: #fff;
    }
    p{
        font-size: 12px;
    }
}
;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:170");