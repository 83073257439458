@use "sass:meta" as ---5bjxerk8lwt; /*player.js*/
 p{
    margin: 0px !important;
  }
  
  p.controlsTime {
 /*   position: relative;
   top: 4.9rem;
   left: 0rem; */
 }
 .controlsTime-end{
 /*   position: relative;
   top: 4.9rem;
   left: -9.5rem; */
   color: white;
 }
 .volume-icon{
   width: 25px;
   height: 25px;
   top: 5rem;
   left: 20rem;
 }
 .download-icons{
   width: 31%
 }
 .volume-icon:hover {
   cursor: pointer;
 }
 .volume-horizontal{
 width: 80% !important;
 display: inline-block !important;
 vertical-align: middle;
 }
 .thumbnail{
   position: absolute;
   min-height: 348px;
   width: 100%;
 }
 .volume-top{
   top: 4rem;
 }
 .rangeslider {
   /*   margin: 58px 18px; */
     position: relative;
     background: #e6e6e6;
     touch-action: none;
 }
 .rangeslider-horizontal .rangeslider__handle {
     width: 10px;
     height: 10px;
     border-radius: 30px;
     top: 50%;
     transform: translate3d(-50%, -50%, 0);
 }
 .rangeslider-horizontal .rangeslider__fill {
     
     background-color:#fbb002;
     top: 0;
     height: 7px;
     width: 100%;
    
 }
 
 
 
   .music-profile{
       width:50px;
       height:50px;
       border-radius:50px;
 }
  
 .music-icons{
   width:20px;
   height:20px;
   }
   .greytext{
       color:grey;
   }
   .install-btn{
       padding:12px 37px;
       border-radius:6px;
       color:white;
       font-family:$font-bold;
   }
   .install-btn:hover{
     color:#ccc;
   }
   .margin-left{
     margin-left:-13px;
   }
   .music_card{
     height: 348px;
     width:100%;
     @include border-radius-seprate(10px,10px,10px,10px);
     object-fit: cover
 
   }
   .video_card{
     height: 348px;
     width:100%;
     object-fit: cover
   }
   .share-screen-base{
     min-height: 70vh;
   }
   
   
   
   /* Video */
   
   .video {
     width: 100vw;
   
   }
   
   /* Controls */
   
   .controlsContainer {
    padding: 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
     background-color:rgba(0,0,0,0.5) ;
     @include border-radius-seprate(0,0,10px,10px);
   }
   .volume-controls{
        position: absolute;
        top: 0;
        right: 0;
        background: rgba(0,0,0,0.5);
        padding: 10px;
        @include  border-radius(10px)
   }
   
   
   .controlsIcon {
     width: 40px;
     height: 40px;
     cursor: pointer;
     margin-left: 10rem;
     margin-right: 10rem;
   }
   
   .controlsIcon--small {
     display: flex;
     align-items: center;
     /* justify-content: space-evenly; */
     position: absolute;
     top: 50%;
     left: 50%;
   
 }
    
  .profile-photo-base{
    .profile-pic{
      span{
        &:first-child{
          img{
            position: relative !important;
            left: 0 !important;
            z-index: 1;
          }
          
        }
      }
      .img-position{
        position: absolute;
        left: 25px;
        top: 0;
      }
     
    }
    p{
      font-size:13px;
    }
  }
.liked-base{
  right: 0;
  top: 24%;
  padding: 15px 10px;
  background: rgba(0,0,0,0.5);
  @include border-radius-seprate(10px,0,0px,10px);
}
.page-link {
  cursor: pointer;
}
   
   /* The time controls section */
   
   .time_progressbarContainer {
     background-color: gray;
     border-radius: 15px;
     width: 29vw;
     height: 5px;
     z-index: 30;
     position: relative;
     margin: 0 20px;
   }
   
   .time_progressBar {
     border-radius: 15px;
     background-color: indigo;
     height: 100%;
     width: 100%;
   }
   
   .controlsTime {
     color: white;
 
   }
 
   /**
 * Rangeslider
 */
 .rangeslider {
  
     position: relative;
     background: #e6e6e6;
     touch-action: none;
 }
 .rangeslider,
 .rangeslider .rangeslider__fill {
   display: block;
 
 }
 .rangeslider .rangeslider__handle {
   background: #fff;
   border: 1px solid #ccc;
   cursor: pointer;
   display: inline-block;
   position: absolute;
  
 }
 .rangeslider .rangeslider__handle .rangeslider__active {
   opacity: 1;
 }
 .rangeslider .rangeslider__handle-tooltip {
   width: 40px;
   height: 40px;
   text-align: center;
   position: absolute;
   background-color: rgba(0, 0, 0, 0.8);
   font-weight: normal;
   font-size: 14px;
   transition: all 100ms ease-in;
   border-radius: 4px;
   display: inline-block;
   color: white;
   left: 50%;
   transform: translate3d(-50%, 0, 0);
 }
 .rangeslider .rangeslider__handle-tooltip span {
   margin-top: 12px;
   display: inline-block;
   line-height: 100%;
 }
 .rangeslider .rangeslider__handle-tooltip:after {
   content: ' ';
   position: absolute;
   width: 0;
   height: 0;
 }
 /**
 * Rangeslider - Horizontal slider
 */
 .rangeslider-horizontal {
   height:2px;
   border-radius: 4px;
   width: 100%;
  /*  margin-bottom: -64px; */
 }
 .rangeslider-horizontal .rangeslider__fill {
   height: 100%;
   //background-color: $music-theme-font;
   border-radius: 10px;
   top: 0;
 }
 .rangeslider-horizontal .rangeslider__handle {
   width: 10px;
   height: 10px;
   border-radius: 30px;
   top: 50%;
   transform: translate3d(-50%, -50%, 0);
 }
 .rangeslider-horizontal .rangeslider__handle:after {
   content: ' ';
   position: absolute;
   width: 5px;
   height: 5px;
   top: 2.5px;
   left: 2.5px;
   border-radius: 50%;
   background-color: #dadada;
 
 }
 
 .rangeslider-horizontal .rangeslider__handle-tooltip {
   top: -55px;
 }
 .rangeslider-horizontal .rangeslider__handle-tooltip:after {
   border-left: 8px solid transparent;
   border-right: 8px solid transparent;
   border-top: 8px solid rgba(0, 0, 0, 0.8);
   left: 50%;
   bottom: -8px;
   transform: translate3d(-50%, 0, 0);
 }
 /**
 * Rangeslider - Vertical slider
 */
 .rangeslider-volume {
   margin: 20px auto;
   height: 150px;
   max-width: 8px;
   background-color: transparent;
 }
 .rangeslider-volume .rangeslider__fill,
 .rangeslider-volume .rangeslider__handle {
   position: absolute;
 }
 .rangeslider-volume .rangeslider__fill {
   width: 100%;
   height: 142px;
  
   box-shadow: none;
   bottom: 0;
   top: -15rem;
   left: -21rem;
 }
 .rangeslider-volume .rangeslider__handle {
   width: 24px;
   height: 6px;
   left: -21.5rem;
   top: -15rem !important;
   box-shadow: none;
 }
 .rangeslider-volume .rangeslider__handle-tooltip {
   left: -100%;
   top: 50%;
   transform: translate3d(-50%, -50%, 0);
 }
 .rangeslider-volume .rangeslider__handle-tooltip:after {
   border-top: 8px solid transparent;
   border-bottom: 8px solid transparent;
   border-left: 8px solid rgba(0, 0, 0, 0.8);
   left: 100%;
   top: 12px;
 }
 /**
 * Rangeslider - Reverse
 */
 .rangeslider-reverse.rangeslider-horizontal .rangeslider__fill {
   right: 0;
 }
 .rangeslider-reverse.rangeslider-volume .rangeslider__fill {
   top: 0;
   bottom: inherit;
 }
 /**
 * Rangeslider - Labels
 */
 .rangeslider__labels {
   position: relative;
 }
 .rangeslider-volume .rangeslider__labels {
   position: relative;
   list-style-type: none;
   margin: 0 0 0 24px;
   padding: 0;
   text-align: left;
   width: 250px;
   height: 100%;
   left: 10px;
 }
 .rangeslider-volume .rangeslider__labels .rangeslider__label-item {
   position: absolute;
   transform: translate3d(0, -50%, 0);
 }
 .rangeslider-volume .rangeslider__labels .rangeslider__label-item::before {
   content: '';
   width: 10px;
   height: 2px;
   background: black;
   position: absolute;
   left: -14px;
   top: 50%;
   transform: translateY(-50%);
   z-index: -1;
 }
 .rangeslider__labels .rangeslider__label-item {
   position: absolute;
   font-size: 14px;
   cursor: pointer;
   display: inline-block;
   top: 10px;
   transform: translate3d(-50%, 0, 0);
 }
 
;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:190");