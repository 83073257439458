@use "sass:meta" as ---5bjxerk8lwt;.homePageBase {
    background: $primaryColor;
    .logo {
        max-width: 200px;
    }
    .dropdown-toggle {
        cursor: pointer;
        width: 50px;
        height: 50px;
        display: inline-block;
        img {
            width: 100%;
            @include border-radius(50%);
        }
    }
    .dropdown-menu {
        padding: 0;

        a {
            padding: 10px 12px;
            font: 16px $font-semibold;
            cursor: pointer;
            &:hover {
                background-color: $adminthemebg;
                color: $secondaryColor;
            }
        }
    }
}
.headBorder {
    border-bottom: 1px solid #233051;
}
.uploadBtnBase {
    .uploadBtn {
        padding: 10px;
        display: inline-block;
        color: $themebg;
        width: 100%;
        background-color: $primaryColor;
        border: none;
        text-decoration: none;
        &:hover {
            text-decoration: none;
            background-color: $themebg;
            color: #fff;
            a {
                color: #fff;
            }
        }
    }
}
//reuqst-song-modal
.RequestSongModal {
    .sign_in_inputs {
        input {
            background: none !important;
        }
        textarea {
            resize: none;
            border: 1px solid #d1d1d1;
            &::placeholder{
                font-family: $font-regular;
                color: #858585;
                font-size: 14px;
            }
        }
    }
}

;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:167");