@use "sass:meta" as ---5bjxerk8lwt;.userLanding {
    h1 {
        font: 36px $font-light;
        color: #fff;
    }
    .language-tag {
        background: rgb(185, 209, 235);
        border-radius: 50px;
        padding: 5px;
    }
    background: $themebg url(../../../assets/images/myImages/landbg.png);
    background-repeat: no-repeat;
    background-position: bottom;
}
.userSongList {
    table {
        margin-top: 30px;
        thead {
            background-color: #ccc;
        }
        td {
            padding: 0px 15px !important;
        }
    }
}
.imageCover {
    object-fit: cover;
    border-radius: 5%;
}

;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:188");