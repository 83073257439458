@use "sass:meta" as ---5bjxerk8lwt;$color_1: white;
$font-family_1: "Quicksand", sans-serif;

.NewTodoForm {
    margin-top: 3rem;
    display: flex;
    flex-wrap: wrap;
    margin-top: -8px;

    label {
        min-width: 100%;
        margin-bottom: 0.5rem;
        font-size: 1.3rem;
    }

    input {
        flex-grow: 1;
        border: none;
        background: #f7f1f1;
        padding: 0 1.5em;
        font-size: initial;
        font-family: $font-family_1;
        height: 3rem;
    }

    button {
        padding: 5px 20px;
        border: none;
        background: $primaryColor;
        color: $color_1;
        text-transform: uppercase;
        border-radius: 20px;

        margin-left: 5px;
        cursor: pointer;
        transform: background 0.2s ease-out;
        font-family: $font-family_1;


        &:hover {
            background: $primaryColor;
        }
    }
}
;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:235");