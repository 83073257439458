@use "sass:meta" as ---5bjxerk8lwt;.sectionBase {
    background: $primaryColor;
    h1 {
        font: 36px $font-light;
        color: #fff;
    }
    .singImgContainer img {
        height: 500px;
    }
}
.yoyoBg {
    background: url(../../../assets/images/myImages/yoyobannerBg.png);
    background-repeat: no-repeat;
    width: 100%;
}

;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:174");