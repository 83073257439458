@use "sass:meta" as ---5bjxerk8lwt;@media only screen and (min-width: 1400px) and (max-width: 1600px) {
}
@media only screen and (min-width: 1233px) and (max-width: 1400px) {
}
@media only screen and (max-width: 1232px) {
}
@media only screen and (max-width: 1023px) {
}
@media only screen and (min-width: 992px) and (max-width: 1180px) {
}
@media only screen and (min-width: 768px) and (max-width: 850px) {
}

@media only screen and (max-width: 767px) {
    .volume-horizontal {
        width: 93% !important;
    }
    .share-screen-base {
        margin-top: 30px;
    }
     //header
    .header-brand{
        svg{
            display: none;
        }
    }
}

//tab
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    //privacy
    .PrivacyBase {
        h1 {
            font-size: 20px;
        }
        h2 {
            font-size: 18px;
        }
        h3 {
            font-size: 16px;
        }
        p {
            font-size: 16px;
        }
        .px-5 {
            padding-right: 2rem !important;
            padding-left: 2rem !important;
        }
        .py-5 {
            padding-top: 2rem !important;
            padding-bottom: 2rem !important;
        }
    }
    //footer
    .footerBase {
        ul {
            text-align: start;
            padding-left: 33px;
        }
        h2 {
            font: 20px PoppinsLight;
            text-align: end;
            padding-right: 33px;
        }
        .downloadUl {
            text-align: end !important;
            padding-right: 33px;
            display: flex !important;
        }
    }
    //header
    .headBorder {
        display: flex;
        align-items: center;
    }
}

//mobile
@media only screen and (min-width: 320px) and (max-width: 576px) {
   
    //privacy
    .PrivacyBase {
       /*  h1 {
            font-size: 19px;
        } */
        h2 {
            font-size: 18px;
            line-height: 24px;
        }
        h3 {
            font-size: 16px;
        }
        p {
            font-size: 16px;
        }
        .px-5 {
            padding-right: 2rem !important;
            padding-left: 2rem !important;
        }
        .py-5 {
            padding-top: 0rem !important;
            padding-bottom: 2rem !important;
        }
    }
    //footer
    .footerBase {
        ul {
            text-align: center;
        }
        h2 {
            font: 20px PoppinsLight;
            text-align: center;
        }
        .downloadUl {
            li {
                display: block !important;
                padding-bottom: 23px;
                margin-right: 0 !important;
            }
        }
    }
    //header
    .headBorder {
        display: flex;
        align-items: center;
    }
}

;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:228");