@use "sass:meta" as ---5bjxerk8lwt;$color_1: #fff;

.TodoList {
    padding: 8rem 0rem 1rem;
    max-width: 82%;
    margin-top: -254px;




    ul {
        margin-top: 6.0rem;
        list-style: none;
        margin-left: -32px;
    }

    h1 {
        font-weight: normal;
        font-size: 2.6rem;
        letter-spacing: 0.05em;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);

        span {
            display: block;
            font-size: 0.8rem;
            margin-bottom: 0.7rem;
            margin-left: 3px;
            margin-top: 0.2rem;
        }
    }
}


.fa-trash:before {
    content: "\f1f8" !important;
}

.fa,
.fas {
    font-weight: 900 !important;
}
;@include ---5bjxerk8lwt.load-css("sass-embedded-legacy-load-done:234");